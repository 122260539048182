import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ButtonComponent from "./../../components/ui/buttonComponent/button.component";
import './Dashboard.css';
import SIMS from "../../img/SIMS.png";
import PupilProgress from "../../img/PupilProgress.png";
import Classroom365 from "../../img/Classroom365.png";
import refreshToken from '../../utils/apiUtils';

const Dashboard = () => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState('');
  const [role, setRole] = useState('');
  const [classes, setClasses] = useState([]);
  const token = localStorage.getItem('token');

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);

    const storedUserName = localStorage.getItem("userName");
    const storedRole = localStorage.getItem("role");

    if (storedUserName) {
      setUserName(storedUserName);
    }
    if (storedRole) {
      setRole(storedRole);
    }

    if (storedRole === 'teacher') {
      const fetchClasses = async (currentToken) => {
        try {
          const response = await axios.get(
            "https://backend-master-v8ob.onrender.com/api/v1/stem@home/teacher/classes",
            {
              headers: { 
                "Content-Type": "application/json",
                "Authorization": `Bearer ${currentToken}`
              }
            }
          );
          setClasses(response.data.classes);
          console.log("Fetched classes:", response.data.classes);
        } catch (error) {
          if (error.response && error.response.status === 401) { // If token expired
            try {
              const newToken = await refreshToken(); // Refresh token
              if (newToken) {
                localStorage.setItem('token', newToken); // Save the new token
                await fetchClasses(newToken); // Retry fetching classes with the new token
              } else {
                console.error("Session expired. Please log in again.");
              }
            } catch (refreshError) {
              console.error("Failed to refresh token. Please log in again.", refreshError);
            }
          } else {
            console.error("Error fetching classes:", error);
          }
        }
      };

      fetchClasses(token);
    }
  }, [token]);

  const handleStartSubmission = () => {
    navigate('/start-submission');
  };

  const handleViewSubmissions = () => {
    navigate('/submissions');
  };

  return (
    <div className="dashboard-container" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', height: '100vh', paddingTop: '20px' }}>
      <h1>Welcome, {userName}!</h1>

      {role === 'teacher' ? (
        <>
          <p>Integrate with:</p>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
            <img 
              src={SIMS} 
              alt="Integration 1" 
              style={{ width: '100px', margin: '10px', border: '1px solid black', cursor: 'pointer', flex: '1 1 auto' }} 
              onClick={() => alert('Integration coming soon!')}
            />
            <img 
              src={PupilProgress} 
              alt="Integration 2" 
              style={{ width: '100px', margin: '10px', border: '1px solid black', cursor: 'pointer', flex: '1 1 auto' }} 
              onClick={() => alert('Integration coming soon!')}
            />
            <img 
              src={Classroom365} 
              alt="Integration 3" 
              style={{ width: '100px', margin: '10px', border: '1px solid black', cursor: 'pointer', flex: '1 1 auto' }} 
              onClick={() => alert('Integration coming soon!')}
            />
          </div>
          {classes.length > 0 ? (
            classes.map((classItem) => (
              <ButtonComponent
                key={classItem._id}
                className="class-button"
                onClick={() => navigate(`/class/${classItem._id}`)} // Navigate to a specific class page
              >
                {classItem.name}
              </ButtonComponent>
            ))
          ) : (
            <p>No classes available</p>
          )}
        </>
      ) : (
        <>
          <ButtonComponent variant="primary" style={{ margin: '10px', backgroundColor: '#0E93CD' }} onClick={handleViewSubmissions}>
            View submissions
          </ButtonComponent>

          <ButtonComponent variant="danger" style={{ margin: '10px', backgroundColor: '#D91A76' }} onClick={handleStartSubmission}>
            Start submission
          </ButtonComponent>
        </>
      )}
    </div>
  );
};

export default Dashboard;
