import { useState, useEffect } from "react";
import pencilImage from '../../../assets/img/pencil.png';
import "./camera.styles.scss";
import ButtonComponent from "../buttonComponent/button.component";
import { connect } from "react-redux";
import { setImageSrc } from "./../../../redux/imageReducer/image.action";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import graphIcon from '../../../../src/img/graph-icon.png';
import tableIcon from '../../../../src/img/table-icon.png';
import BiologyPhotosynthesis1 from '../../../../src/img/1Photosynthesis.PNG'; 
import BiologyPhotosynthesis2 from '../../../../src/img/2Photosynthesis.png'; 
import BiologyFieldInvestigation1 from '../../../../src/img/1FieldInvestigation.PNG'; 
import BiologyFieldInvestigation2 from '../../../../src/img/2FieldInvestigation.PNG'; 
import BiologyOsmosis1 from '../../../../src/img/1Osmosis.PNG'; 
import BiologyOsmosis2 from '../../../../src/img/2Osmosis.PNG'; 
import ChemistryNeutralisation1 from '../../../../src/img/1Neutralisation.PNG'; 
import ChemistryNeutralisation2 from '../../../../src/img/2Neutralisation.PNG'; 
import ChemistryWaterSeparation1 from '../../../../src/img/1WaterSeparation.PNG'; 
import ChemistryChromatography1 from '../../../../src/img/1Chromatography.PNG';
import PhysicsSpringExtension1 from '../../../../src/img/1SpringExtension.PNG'; 
import PhysicsSpringExtension2 from '../../../../src/img/2SpringExtension.PNG'; 
import PhysicsAbsorptionRadiation1 from '../../../../src/img/1AbsorptionRadiation.PNG'; 
import PhysicsAbsorptionRadiation2 from '../../../../src/img/2AbsorptionRadiation.PNG'; 
import PhysicsInsulation1 from '../../../../src/img/1WithInsulation.PNG'; 
import PhysicsInsulation2 from '../../../../src/img/2WithInsulation.PNG';
import PhysicsInsulation3 from '../../../../src/img/3WithoutInsulation.PNG'; 
import PhysicsInsulation4 from '../../../../src/img/4WithoutInsulation.PNG';
import { setComponent } from "../../../pages/uploadPage/uploadPage.util";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import refreshAuthToken from "../../../utils/apiUtils";


// Mapping of practical names to their respective icons for each submission stage
const practicalIcons = {
  //Biology
  "Photosynthesis": { stage1: BiologyPhotosynthesis1, stage2: BiologyPhotosynthesis2 },
  "Field Investigation": { stage1: BiologyFieldInvestigation1, stage2: BiologyFieldInvestigation2 },
  "Osmosis": { stage1: BiologyOsmosis1, stage2: BiologyOsmosis2 },
  //Chemistry
  "Neutralisation": { stage1: ChemistryNeutralisation1, stage2: ChemistryNeutralisation2},
  "Water Separation": { stage2: ChemistryWaterSeparation1},
  "Chromatography": { stage2: ChemistryChromatography1},
  //Physics
  "Spring Extension": { stage1: PhysicsSpringExtension1, stage2: PhysicsSpringExtension2},
  "Absorption Radiation": { stage1: PhysicsAbsorptionRadiation1, stage2: PhysicsAbsorptionRadiation2},
  "Insulation": { stage1: PhysicsInsulation1, stage2: PhysicsInsulation2, stage3: PhysicsInsulation3, stage4: PhysicsInsulation4},
};


function Camera({ setImageSrc, data, subject }) {
  // console.log("RESULT FROM REDUX----", data);
  const navigate = useNavigate();
  const location = useLocation();
  const [imgSrc, setImgSrc] = useState(null);
  const [error, setError] = useState(null);
  const [texStractValue, setTextStractValue] = useState(null);
  const [clicked, setClicked] = useState(false);
  const [receivedData, setReceivedData] = useState(null);
  const [uploadStage, setUploadStage] = useState(1);
  const [imagesList, setImagesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState("");
  const [responseData, setResponseData] = useState(null);
  const [showResponseData, setShowResponseData] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [feedbackData, setFeedbackData] = useState(null);
  const [showOnlyTable, setShowOnlyTable] = useState(false);
  const [isFinalSubmission, setIsFinalSubmission] = useState(false);
  const [submissionCount, setSubmissionCount] = useState(0);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const getToken = () => localStorage.getItem('token');

  useEffect(() => {
    if (!location.state?.fromStartSubmission) {
      navigate('/start-submission');
    }

    if (location.state?.practical === "Water Separation" || location.state?.practical === "Chromatography") {
      setUploadStage(2);
    }
  }, [location, navigate]);

  useEffect(() => {
    const navbarHeight = document.querySelector('.navbar').offsetHeight;
    const extraGap = 0;
    document.querySelector('.camera-container').style.paddingTop = `${navbarHeight + extraGap}px`;
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) { // 5MB limit
        setError("File size exceeds the 5MB limit.");
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        const imageData = reader.result;
        setImgSrc(imageData);
        setImageSrc(imageData);
        setImagesList((prevList) => [...prevList, imageData]); // Add to images list
      };
      reader.readAsDataURL(file);
    }
  };

  const postImage = async () => {
    // console.log(imagesList);
    try {
        if (imagesList.length === 0) {
            setError("No images to submit.");
            return;
        }

        // Ensure the correct image order or processing if necessary
        // e.g., the first image might be a graph and the second one a table.

        // Send all images as an array to the server
        const result = await axios.post(
            // "https://backend-master-v8ob.onrender.com/api/v1/stem@home/upload",
            //"https://backend-master-v8ob.onrender.com/api/v1/stem@home/upload",
            "https://backend-master-v8ob.onrender.com/api/v1/stem@home/upload",
            { imgsource: imagesList },
            {
                headers: { "Content-Type": "application/json" }, // Ensure correct content type
            }
        );
        

        const textstractData = result.data.data.formData[0];
        if (!textstractData) {
            throw new Error("No data received from the server");
        }

        const spreadData = {};
        for (let [key, value] of Object.entries(textstractData)) {
            key !== "1" ? (spreadData[key] = value) : console.log("it Hit 11");
        }

        setTextStractValue(spreadData);
        setReceivedData(result.data.data.formData);
        // console.log(result.data.data.keys);
        setImagesList(result.data.data.keys);

        // Clear the images list after submission
        // setImagesList([]);
        setImgSrc(null);
        setUploadStage(1); // Reset to stage 1 for future uploads

    } catch (error) {
        setError("An error occurred while submitting the images. Please try again.");
        console.error("Error details:", {
            message: error.message,
            response: error.response ? error.response.data : null,
            config: error.config,
        });
    }
};

  

  const handleSubmit = async () => {
    setError(null);

    // For practicals with only one upload stage
    if (location.state?.practical === "Water Separation" || location.state?.practical === "Chromatography") {
        await postImage();
    } else if (location.state?.practical === "Insulation") {
        // For Insulation practical with four upload stages
        if (uploadStage === 1 || uploadStage === 3) {
            // After the first and third uploads (graphs)
            setImgSrc(null);
            setUploadStage(uploadStage + 1); // Move to the next stage
        } else if (uploadStage === 2) {
            // After the second upload (table), just save the image locally
            setImgSrc(null);
            setUploadStage(uploadStage + 1); // Move to the next stage
        } else if (uploadStage === 4) {
            // After the fourth upload (table), send all images to AWS
            await postImage();
            setUploadStage(1); // Reset to stage 1 after the final upload
        }
    } else {
        // For practicals with two upload stages
        if (uploadStage === 1) {
            // After the first upload (graph)
            setImgSrc(null);
            setUploadStage(2); // Move to the second stage for table upload
        } else {
            // After the second upload (table)
            await postImage();
        }
    }
    setSubmissionCount(prevCount => prevCount + 1);
  };

  useEffect(() => {
    const practicals = [
      'Photosynthesis', 'Field Investigation', 'Osmosis',
      'Neutralisation', 'Water Separation', 'Chromatography',
      'Spring Extension', 'Absorption Radiation', 'Insulation'
    ];
    if (submissionCount >= 1 && practicals.includes(location.state?.practical) && receivedData) {
      setShowOnlyTable(true);
    }
  }, [submissionCount, location.state?.practical, receivedData]);

  const handleUploadFromGallery = () => {
    document.getElementById('fileInput').click();
  };

  const handleTakePhoto = () => {
    document.getElementById('cameraInput').click();
  };

  const getIconForPractical = () => {
    const practical = location.state?.practical;
    // console.log(practical);
    if (practical && practicalIcons[practical]) {
      return practicalIcons[practical][`stage${uploadStage}`] || (uploadStage === 1 ? graphIcon : tableIcon);
    }
    return uploadStage === 1 ? graphIcon : tableIcon;
  };

  const submitTableData = async () => {
    const token = localStorage.getItem('token'); // Retrieve the current token
  
    // Function to perform the actual submission
    const performSubmission = async (currentToken) => {
      try {
        setLoading(true);
        setProgress(0);
  
        const practical = location.state?.practical;
  
        // Check if receivedData exists
        if (!receivedData || receivedData.length === 0) {
          throw new Error('No data available to submit');
        }
  
        // Prepare data for submission
        let results;
        if (practical === "Insulation" && receivedData.length === 2) {
          results = {
            withInsulation: Object.values(receivedData[0][0] || {})
              .slice(1)
              .map(obj => 
                Object.values(obj || {}).map(item => 
                  typeof item === 'string' ? item.trim() : item
                )
              ),
            noInsulation: Object.values(receivedData[1][0] || {})
              .slice(1)
              .map(obj => 
                Object.values(obj || {}).map(item => 
                  typeof item === 'string' ? item.trim() : item
                )
              )
          };
        } else {
          results = Object.values(receivedData[0] || {})
            .slice(1)
            .map(obj => 
              Object.values(obj || {}).map(item => 
                typeof item === 'string' ? item.trim() : item
              )
            );
        }
  
        console.log("Results to submit:", results);
        console.log("Images List:", imagesList);
  
        const response = await axios.post(
          "https://backend-master-v8ob.onrender.com/api/v1/stem@home/student/save",
          { practical, result: results, imagesList },
          {
            headers: { 
              "Content-Type": "application/json",
              "Authorization": `Bearer ${currentToken}`
            }
          }
        );
  
        console.log("Table data submitted successfully:", response.data);
  
        if (response.data && response.data.feedback) {
          let feedbackArray = [];
          if (Array.isArray(response.data.feedback)) {
            if (practical === "Insulation") {
              // Handle Insulation practical feedback
              response.data.feedback.forEach(table => {
                if (Array.isArray(table.feedback)) {
                  table.feedback.forEach(item => {
                    if (item.feedback) {
                      feedbackArray.push(item.feedback);
                    }
                  });
                }
              });
            } else {
              feedbackArray = response.data.feedback.map(item => item.feedback || item.message || item);
            }
          } else if (typeof response.data.feedback === 'object') {
            feedbackArray = [response.data.feedback.feedback || response.data.feedback.message || JSON.stringify(response.data.feedback)];
          } else {
            feedbackArray = [response.data.feedback];
          }
          console.log("Feedback data received:", feedbackArray);
          setFeedbackData(feedbackArray);
        } else {
          console.log("No feedback data in response");
          setFeedbackData(["No feedback available"]);
        }
  
        // Check if this is the final submission based on the practical
        const isFinal = checkIfFinalSubmission(practical, imagesList.length);
        setIsFinalSubmission(isFinal);
  
        let progressInterval = setInterval(() => {
          setProgress(prev => {
            const newProgress = prev + 1;
            if (newProgress >= 100) {
              clearInterval(progressInterval);
              setLoading(false);
              setShowFeedback(true);
              setShowOnlyTable(true);
              setIsConfirmed(true);
              console.log("Progress reached 100%, showing feedback");
              return 100;
            }
            return newProgress;
          });
        }, 50);
      } catch (error) {
        if (error.response && error.response.status === 401) { // If token expired
          try {
            const newToken = await refreshAuthToken(); // Refresh token
            await performSubmission(newToken); // Retry submission with new token
          } catch (refreshError) {
            setError("Failed to refresh token. Please log in again.");
            setLoading(false);
            console.error("Error refreshing token:", refreshError);
          }
        } else {
          console.error("Error submitting table data:", error);
          setError(error.message || "An error occurred while submitting table data");
          setLoading(false);
        }
      }
    };
  
    performSubmission(token); // Start the submission process with the current token
  };

  const checkIfFinalSubmission = (practical, submissionCount) => {
    const practicalStages = {
      'Photosynthesis': 1,
      'Chromatography': 1,
      // Add other practicals and their number of stages here
    };
    return submissionCount === practicalStages[practical];
  };

  const handleImageClick = (url) => {
    setSelectedImageUrl(url);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImageUrl("");
  };

  useEffect(() => {
    const handleButtonClick = (event) => {
      if (event.target.tagName === 'BUTTON') {
        closeModal();
      }
    };

    if (isModalOpen) {
      document.addEventListener('click', handleButtonClick);
    } else {
      document.removeEventListener('click', handleButtonClick);
    }

    return () => {
      document.removeEventListener('click', handleButtonClick);
    };
  }, [isModalOpen]);

  const getBackgroundColor = (subject) => {
    if (!subject) return 'white';
    switch (subject.toLowerCase()) {
      case 'biology':
        return 'lightgreen';
      case 'chemistry':
        return 'lightblue';
      case 'physics':
        return 'pink';
      default:
        return 'white';
    }
  };

  const renderInsulationTables = () => {
    if (location.state?.practical === "Insulation" && receivedData) {
      const withInsulation = receivedData[0] || [{ 0: 0 }];
      const withoutInsulation = receivedData[1] || [{ 0: 0 }];

      return (
        <>
          <div className="insulation-table">
            <h3>With Insulation</h3>
            {setComponent(location.state?.practical, { receivedData: [withInsulation] })}
          </div>
          <div className="insulation-table">
            <h3>Without Insulation</h3>
            {setComponent(location.state?.practical, { receivedData: [withoutInsulation] })}
          </div>
        </>
      );
    }
    return setComponent(location.state?.practical, { receivedData: receivedData || [{ 0: 0 }] });
  };

  useEffect(() => {
    if (showFeedback) {
      window.scrollTo(0, 0);
      const timeoutId = setTimeout(() => {
        window.removeEventListener('scroll', handleScroll);
      }, 3000);

      const handleScroll = () => {
        window.scrollTo(0, 0);
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
        clearTimeout(timeoutId);
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [showFeedback]);

  return (
    <div className={`camera-container ${showOnlyTable ? 'final-submission' : ''}`}>
      {!showOnlyTable && (
        <>
          <div className="topic-practical-container">
            <div className="topic-practical-text">
              <div style={{ fontSize: '24px', fontWeight: 'bold', textAlign: 'center' }}>
                {location.state?.topic}
              </div>
              <div style={{ fontSize: '20px', fontWeight: 'normal', textAlign: 'center' }}>
                {location.state?.practical}
              </div>
            </div>
          </div>
          <div className="upload-instructions">
            <h2>
              {location.state?.practical === "Water Separation" || location.state?.practical === "Chromatography"
                ? "Upload page 1 / 1"
                : location.state?.practical === "Insulation"
                ? `Upload page [${uploadStage} / 4]`
                : `Upload page [${uploadStage} / 2]`}
            </h2>
            <p>Example sheet</p>
            <img src={getIconForPractical()} alt="Practical Icon" style={{ width: '300px', height: '300px' }} />
          </div>
          {error && <div className="error-message">{error}</div>}
          <div className="button-container">
            <ButtonComponent onClick={handleUploadFromGallery} className="blur-button">
              Upload Image
            </ButtonComponent>
            <div style={{ margin: '10px' }} />
            <ButtonComponent onClick={handleTakePhoto} className="green-button">
              Take Photo
            </ButtonComponent>
          </div>
          {imgSrc && <p>Your sheet:</p>}
          <div className="camera-border">
            <div className="webcam-wrapper">
              {!imgSrc ? (
                <>
                  <input
                    id="fileInput"
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                  />
                  <input
                    id="cameraInput"
                    type="file"
                    accept="image/*"
                    capture="environment"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                  />
                </>
              ) : (
                <img
                  style={{ width: "250px" }}
                  src={imgSrc}
                  alt="user captured img"
                  onClick={() => handleImageClick(imgSrc)}
                />
              )}
            </div>
          </div>
          {imgSrc && (
            <div>
              <ButtonComponent onClick={handleSubmit}>
                {location.state?.practical === "Water Separation" || location.state?.practical === "Chromatography"
                  ? 'Submit'
                  : location.state?.practical === "Insulation"
                  ? uploadStage === 1 || uploadStage === 3 ? 'Submit Graph' : 'Submit Table'
                  : uploadStage === 1 ? 'Submit Graph' : 'Submit Table'}
              </ButtonComponent>
            </div>
          )}
          <input
            id="fileInput"
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
          <input
            id="cameraInput"
            type="file"
            accept="image/*"
            capture="environment"
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
        </>
      )}
      
      <div className="table-container-wrapper">
        {receivedData && !showFeedback && (
          <div className="table-container">
            <h2 className="confirm-values-title">
              {isConfirmed ? "Confirmed Values" : "Confirm Values"}
            </h2>
            {renderInsulationTables()}
            <ButtonComponent onClick={submitTableData}>Submit Table Data</ButtonComponent>
          </div>
        )}
        {loading && (
          <div className="loading-screen">
            <h2>Marking your work now</h2>
            <div className="pencil-container">
              <div className="pencil" style={{ backgroundImage: `url(${pencilImage})` }}>
                <div className="pencil-progress-container">
                  <div className="pencil-progress" style={{ width: `${progress}%` }}>
                    <span className="pencil-progress-text">{progress}%</span>
                  </div>
                </div>
              </div>
            </div>
            <p>Please wait...</p>
          </div>
        )}
        {showFeedback && feedbackData && (
          <div className="feedback-and-table-container" style={{ backgroundColor: getBackgroundColor(subject) }}>
            <div className="feedback-carousel-container">
              <Carousel showThumbs={false} showStatus={false} showArrows={true} infiniteLoop={true}>
                {feedbackData.map((feedback, index) => (
                  <div key={index} className="feedback-item">
                    <h3>Feedback {index + 1}</h3>
                    <p>{feedback}</p>
                  </div>
                ))}
              </Carousel>
            </div>
            <div className="table-container">
              <h2 className="confirm-values-title">Confirmed Values</h2>
              {renderInsulationTables()}
            </div>
            <ButtonComponent onClick={() => navigate('/dashboard')}>
              I understand
            </ButtonComponent>
          </div>
        )}
      </div>

      {isModalOpen && (
        <div className="submissions-modal">
          <div className="submissions-modal-content">
            <img src={selectedImageUrl} alt="Selected" className="submissions-modal-image" />
            <button className="submissions-close-button" onClick={closeModal}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setImageSrc: (data) => dispatch(setImageSrc(data)),
});

export default connect(null, mapDispatchToProps)(Camera);