import exportModule from "./../../components/form/TableComponent/TableComponent.component";
const {
  Chromatography,
  Photosynthesis,
  Absorptionandradiation,
  Neutralisation,
  Springextension,
  Fieldinvestigation,
  Osmosis,
  Waterseparation,
  Insulation
} = exportModule;

export const setComponent = (text, data) => {
  // console.log(text);
  // console.log(data);
  text = text.toLowerCase().replaceAll(" ", "");
  console.log(text);
  if (text === "chromatography") {
    return <Chromatography data={data} />;
  } else if (text === "photosynthesis") {
    return <Photosynthesis data={data} />;
  } else if (text === "absorptionradiation") {
    return <Absorptionandradiation data={data} />;
  } else if (text === "neutralisation") {
    return <Neutralisation data={data} />;
  } else if (text === "springextension") {
    return <Springextension data={data} />;
  } else if (text === "fieldinvestigation") {
    return <Fieldinvestigation data={data} />;
  } else if (text === "osmosis") {
    return <Osmosis data={data} />;
  } else if (text === "waterseparation") {
    return <Waterseparation data={data} />;
  } else if (text === "insulation") {
    return <Insulation data={data} />;
  } else {
    return <h1>THE SELECT COMPONENT OR TABLE TO BE RENDERED DOES NOT YET EXIST</h1>;
  }
};
