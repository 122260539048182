import { Route, Routes, Navigate } from "react-router-dom";
// import UploadPage from "./pages/uploadPage/uploadPage.page";
// import HomePage from "./pages/HomePage/homePage.page";
import TeacherPage from "./pages/TeacherPage/teacher.page";
import Camera from "./components/ui/Camera/camera.component";
import RenderRoute from "./hooks/renderRouteMiddleware";
import Login from "./pages/login/Login";
import Dashboard from "./pages/dashboard/Dashboard";
import Signup from "./pages/signup/Signup";
import Header from "./pages/header/Header";
import StartSubmission from "./pages/startSubmission/StartSubmission";
import Submissions from "./pages/submissions/Submissions";
import AdminDashboard from "./pages/adminDashboard/AdminDashboard";
import PrivateRoute from "./components/PrivateRoute";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import useUserRole from "./hooks/useUserRole";
import ClassDetails from './pages/classDetails/classDetails';

function App() {
  const { role } = useUserRole(); // Use the custom hook to get the user role

  return (
    <>
      <Header />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Signup />} />
        <Route element={<RenderRoute />}>
          <Route element={<PrivateRoute />}>
            <Route path="/dashboard" element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            } />
            <Route
              path="/admin-dashboard"
              element={
                <ProtectedRoute role="admin">
                  <AdminDashboard />
                </ProtectedRoute>
              }
            />
            <Route path="/start-submission" element={
              <ProtectedRoute>
                <StartSubmission />
              </ProtectedRoute>
            } />
            <Route path="/" element={<Navigate to={role === 'teacher' ? "/admin-dashboard" : "/dashboard"} />} />
            {/* <Route path="/upload" element={
              <ProtectedRoute>
                <UploadPage />
              </ProtectedRoute>
            } /> */}
            <Route path="/teacher" element={
              <ProtectedRoute>
                <TeacherPage />
              </ProtectedRoute>
            } />
            <Route path="/takeImage" element={
              <ProtectedRoute>
                <Camera />
              </ProtectedRoute>
            } />
            <Route path="/submissions" element={
              <ProtectedRoute>
                <Submissions />
              </ProtectedRoute>
            } />
            <Route path="/class/:classId" element={
              <ProtectedRoute>
                <ClassDetails />
              </ProtectedRoute>
            } />
          </Route>
        </Route>
      </Routes>
    </>
  );
}

export default App;
