import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children, role }) => {
  const userRole = localStorage.getItem('role');
  const token = localStorage.getItem('token');

  if (!token) {
    return <Navigate to="/login" state={{ error: "Please log in to continue." }} />;
  }

  if (userRole === 'admin' && window.location.pathname !== '/admin-dashboard') {
    return <Navigate to="/admin-dashboard" />;
  }

  if (role && userRole !== role) {
    return <Navigate to={userRole === 'teacher' ? '/admin-dashboard' : '/dashboard'} />;
  }

  return children;
};

export default ProtectedRoute;
