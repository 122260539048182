import { textractDataType } from "./textractdata.types";
const INITIAL_STATE = {
  resultData: null,
};

const textractDataReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case textractDataType.SET_RESULT_DATA:
      return {
        ...state,
        resultData: action.payload,
      };
    default:
      return state;
  }
};

export default textractDataReducer;
