import { subjectData } from "./subject.types";
const INITIAL_STATE = {
  subjectArray: [
    "Biology-Practical: Photosynthesis",
    "Biology-Practical: Qualitative Reagent Test",
    "Chemistry-Practical: Chromatography",
    "Chemistry-Practical: Rates of Reaction",
    "Physics-Practical:Specific Heat Capacity",
    "Physics-Practical: Radiation and Absorption",
  ],
};

const subjectReducer = (state = INITIAL_STATE, action) => {
  switch (subjectData) {
    default:
      return state;
  }
};

export default subjectReducer;
