import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import textractDataReducer from "./textractdata/textractdata.reducer";
import storage from "redux-persist/lib/storage";
import subjectReducer from "./subjectReducer/subject.reducer";
import imageReducer from "./imageReducer/image.reducer";
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["imgSrc"],
};
// whitelist: ["imgSrc"],
const rootReducer = combineReducers({
  data: textractDataReducer,
  subject: subjectReducer,
  imgSrc: imageReducer,
});

export default persistReducer(persistConfig, rootReducer);

//redux settings
// export default combineReducers({
//   user: UserReducer,
//   hidden: cartToggleReducer,
// });
