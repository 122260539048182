
import { useState, useEffect } from "react";

function useUserRole() {
  const [role, setRole] = useState(null);

  useEffect(() => {
    // Fetch the user role from your authentication service or context
    const fetchUserRole = async () => {
      // Example: Fetch role from local storage or an API
      const userRole = localStorage.getItem("userRole") || "student";
      setRole(userRole);
    };

    fetchUserRole();
  }, []);

  return { role };
}

export default useUserRole;