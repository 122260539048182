import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ButtonComponent from './../../components/ui/buttonComponent/button.component';
import './classDetails.styles.scss';
import refreshToken from '../../utils/apiUtils';

const ClassDetails = () => {
  const { classId } = useParams();
  const [classData, setClassData] = useState(null);
  const [error, setError] = useState(null);
  const token = localStorage.getItem('token');
  const [submissions, setSubmissions] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [selectedSubmission, setSelectedSubmission] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [submissionsPerPage] = useState(5);

  useEffect(() => {
    const fetchClassDetails = async (currentToken) => {
      try {
        const response = await axios.get(
          `https://backend-master-v8ob.onrender.com/api/v1/stem@home/class/${classId}/`,
          {
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${currentToken}`
            }
          }
        );
        setClassData(response.data);
        console.log("Fetched class details:", response.data);
      } catch (error) {
        if (error.response && error.response.status === 401) { // If token expired
          try {
            const newToken = await refreshToken(); // Refresh token
            if (newToken) {
              localStorage.setItem('token', newToken); // Save the new token
              await fetchClassDetails(newToken); // Retry fetching class details with the new token
            } else {
              setError("Session expired. Please log in again.");
            }
          } catch (refreshError) {
            setError("Failed to refresh token. Please log in again.");
            console.error("Error refreshing token:", refreshError);
          }
        } else {
          setError("Error fetching class details.");
          console.error("Error fetching class details:", error);
        }
      }
    };

    fetchClassDetails(token);
  }, [classId, token]);

  const handleStudentClick = async (studentId) => {
    const fetchStudentSubmissions = async (currentToken) => {
      try {
        const teacherId = localStorage.getItem('userId');
        const response = await axios.get(
          `https://backend-master-v8ob.onrender.com/api/v1/stem@home/teacher/student/${studentId}/submissions`,
          {
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${currentToken}`
            }
          }
        );
        setSubmissions(response.data.data);
        setSelectedStudent(studentId);
        setSelectedSubmission(null);
        setCurrentPage(1);
        console.log("Fetched student submissions:", response.data);
      } catch (error) {
        if (error.response && error.response.status === 401) { // If token expired
          try {
            const newToken = await refreshToken(); // Refresh token
            if (newToken) {
              localStorage.setItem('token', newToken); // Save the new token
              await fetchStudentSubmissions(newToken); // Retry fetching student submissions with the new token
            } else {
              setError("Session expired. Please log in again.");
            }
          } catch (refreshError) {
            setError("Failed to refresh token. Please log in again.");
            console.error("Error refreshing token:", refreshError);
          }
        } else {
          setError("Error fetching student submissions.");
          console.error("Error fetching student submissions:", error);
        }
      }
    };

    fetchStudentSubmissions(token);
  };

  const handleSubmissionClick = async (submissionId) => {
    const fetchSubmissionDetails = async (currentToken) => {
      try {
        const response = await axios.get(
          `https://backend-master-v8ob.onrender.com/api/v1/stem@home/teacher/student/${selectedStudent}/submissions/${submissionId}`,
          {
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${currentToken}`
            }
          }
        );
        setSelectedSubmission(response.data);
        console.log("Fetched submission details:", response.data);
      } catch (error) {
        if (error.response && error.response.status === 401) { // If token expired
          try {
            const newToken = await refreshToken(); // Refresh token
            if (newToken) {
              localStorage.setItem('token', newToken); // Save the new token
              await fetchSubmissionDetails(newToken); // Retry fetching submission details with the new token
            } else {
              setError("Session expired. Please log in again.");
            }
          } catch (refreshError) {
            setError("Failed to refresh token. Please log in again.");
            console.error("Error refreshing token:", refreshError);
          }
        } else {
          setError("Error fetching submission details.");
          console.error("Error fetching submission details:", error);
        }
      }
    };

    fetchSubmissionDetails(token);
  };

  const handleImageClick = (url) => {
    setSelectedImageUrl(url);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImageUrl("");
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const getBackgroundColor = (practical) => {
    if (!practical) return '';

    switch (practical.toLowerCase()) {
      case 'photosynthesis':
      case 'field investigation':
      case 'osmosis':
        return 'green-background';
      case 'chromatography':
      case 'water separation':
      case 'neutralisation':
        return 'blue-background';
      case 'spring extension':
      case 'absorption radiation':
      case 'insulation':
        return 'pink-background white-text';
      default:
        return '';
    }
  };

  if (error) {
    return <div>{error}</div>;
  }

  if (!classData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="class-details">
      <h1>Class: {classData.class}</h1>
      <h2>Students:</h2>
      {classData.students.length > 0 ? (
        <div className="student-buttons">
          {classData.students.map((student) => (
            <ButtonComponent
              key={student._id}
              onClick={() => handleStudentClick(student._id)}
            >
              {student.name}
            </ButtonComponent>
          ))}
        </div>
      ) : (
        <p>No students found</p>
      )}

      {selectedStudent && (
        <div className="submissions">
          <h2>Submissions for Student {selectedStudent}:</h2>
          {submissions.length > 0 ? (
            <>
              <ul>
                {submissions
                  .slice((currentPage - 1) * submissionsPerPage, currentPage * submissionsPerPage)
                  .map((submission) => (
                    <li key={submission._id}>
                      <ButtonComponent onClick={() => handleSubmissionClick(submission._id)}>
                        {submission.title} - {submission.submission_date}
                      </ButtonComponent>
                    </li>
                  ))}
              </ul>
              {selectedSubmission && (
                <div className={`submission-details ${getBackgroundColor(selectedSubmission.data.practical)}`}>
                  <h2>Submission Details</h2>
                  <p><strong>Practical: </strong> {selectedSubmission.data.practical}</p>
                  <p><strong>Images: </strong></p>
                  {selectedSubmission.data.imageUrls && selectedSubmission.data.imageUrls.length > 0 ? (
                    <div className="image-gallery">
                      {selectedSubmission.data.imageUrls.map((url, index) => (
                        <img
                          key={index}
                          src={url}
                          alt={`Submission image ${index + 1}`}
                          className="submission-image"
                          onClick={() => handleImageClick(url)}
                        />
                      ))}
                    </div>
                  ) : (
                    <p>No images available</p>
                  )}
                </div>
              )}
              <div className="pagination">
                {Array.from({ length: Math.ceil(submissions.length / submissionsPerPage) }, (_, i) => (
                  <ButtonComponent key={i} onClick={() => paginate(i + 1)}>
                    {i + 1}
                  </ButtonComponent>
                ))}
              </div>
            </>
          ) : (
            <p>No submissions found for this student</p>
          )}
        </div>
      )}

      {isModalOpen && (
        <div className="submissions-modal">
          <div className="submissions-modal-content">
            <img src={selectedImageUrl} alt="Selected" className="submissions-modal-image" />
            <button className="submissions-close-button" onClick={closeModal}>Close</button>
          </div>
        </div>
      )}

      <ButtonComponent onClick={() => window.history.back()}>Back to Dashboard</ButtonComponent>
    </div>
  );
};

export default ClassDetails;
