import { IMAGE_TYPES } from "./image.types";
const INITIAL_STATE = {
  imgSrc: null,
};

const imageReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case IMAGE_TYPES.SETIMAGESRC:
      return { ...state, imgSrc: action.payload };
    default:
      return state;
  }
};
export default imageReducer;
