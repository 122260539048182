import React, { useEffect, useState, useRef } from "react";
import './AdminDashboard.css';
import axios from "axios";
import ButtonComponent from '../../components/ui/buttonComponent/button.component';
import Pagination from '../../components/ui/Pagination/pagination.component'; // Adjust the path if needed
import refreshToken from '../../utils/apiUtils'; // Adjust the path if needed

const AdminDashboard = () => {
  const [className, setClassName] = useState('');
  const [teachers, setTeachers] = useState([]);
  const [students, setStudents] = useState([]);
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [showTeacherList, setShowTeacherList] = useState(false);
  const [showStudentList, setShowStudentList] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [currentTeacherPage, setCurrentTeacherPage] = useState(1);
  const [currentStudentPage, setCurrentStudentPage] = useState(1);
  const [currentClassPage, setCurrentClassPage] = useState(1);
  const [currentView, setCurrentView] = useState(''); // Empty or 'createClass' or 'viewClasses'
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState(null);
  const itemsPerPage = 10;
  
  const classDetailsRef = useRef(null); // Ref to scroll to class details
  
  // Function to handle API requests with token refresh
  const apiRequest = async (request) => {
    try {
      const response = await request();
      console.log("API Request Success:", response);
      return response;
    } catch (error) {
      console.error("API Request Error:", error.response || error);
      if (error.response && error.response.status === 401) {
        // Token expired, try refreshing
        const newToken = await refreshToken();
        if (newToken) {
          // Retry the request with the new token
          return await request();
        }
      }
      throw error;
    }
  };

  // Function to fetch students
  const fetchStudents = async () => {
    try {
      const response = await apiRequest(() => axios.get("https://backend-master-v8ob.onrender.com/api/v1/stem@home/student/allStudents", {
        headers: { 
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem('token')}` // Include the token in the Authorization header
        }
      }));
      setStudents(response.data.students);
    } catch (error) {
      console.error("Error fetching students:", error);
    }
  };

  // Function to fetch teachers
  const fetchTeachers = async () => {
    try {
      const response = await apiRequest(() => axios.get("https://backend-master-v8ob.onrender.com/api/v1/stem@home/teacher/allTeachers", {
        headers: { 
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem('token')}` // Include the token in the Authorization header
        }
      }));
      setTeachers(response.data.teachers);
    } catch (error) {
      console.error("Error fetching teachers:", error);
    }
  };

  // Function to fetch all classes
  const fetchClasses = async () => {
    try {
      const response = await apiRequest(() => axios.get("https://backend-master-v8ob.onrender.com/api/v1/stem@home/class/allClasses", {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem('token')}` // Include the token in the Authorization header
        }
      }));
      setClasses(response.data.classes);
    } catch (error) {
      console.error("Error fetching classes:", error);
    }
  };

  // Handle the click of "Select Teacher" button
  const handleSelectTeacherClick = () => {
    if (className) {
      setShowTeacherList(true);
      fetchTeachers(); // Fetch teachers when the button is clicked
    } else {
      alert("Please enter a class name first.");
    }
  };

  // Handle the selection of a teacher
  const handleTeacherSelection = (teacher) => {
    setSelectedTeacher(teacher);
    setShowTeacherList(false); // Hide the teacher list after selection
    fetchStudents(); // Fetch students after selecting a teacher
    setShowStudentList(true); // Show the student list
  };

  // Handle student selection (toggle)
  const handleStudentSelection = (student) => {
    setSelectedStudents((prevSelected) => {
      if (prevSelected.includes(student._id)) {
        // Remove student if already selected
        return prevSelected.filter((id) => id !== student._id);
      } else {
        // Add student if not already selected
        return [...prevSelected, student._id];
      }
    });
  };

  // Handle confirmation of selected teacher and students
  const handleConfirmSelection = () => {
    if (selectedTeacher && selectedStudents.length > 0) {
      setShowConfirmation(true); // Show confirmation modal
    } else {
      alert("Please select a teacher and at least one student.");
    }
  };

  // Handle class creation with selected teacher and students
  const handleCreateClass = async () => {
    try {
      // Step 1: Create the class with the teacher
      const classResponse = await apiRequest(() => axios.post(
        "https://backend-master-v8ob.onrender.com/api/v1/stem@home/class/create", 
        {
          name: className,
          teacherId: selectedTeacher._id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.getItem('token')}` // Include the token in the Authorization header
          }
        }
      ));
  
      // Ensure the class is successfully created before proceeding
      if (classResponse.status === 201) {
        const classId = classResponse.data.class._id; // Get the class ID from the response
        console.log("Class created:", classResponse.data);

        // Step 2: Assign students to the created class if any are selected
        if (selectedStudents.length > 0) {
          const assignStudentsResponse = await apiRequest(() => axios.post(
            "https://backend-master-v8ob.onrender.com/api/v1/stem@home/student/assign-students", 
            {
              classId: classId,
              studentIds: selectedStudents
            },
            {
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('token')}` // Include the token in the Authorization header
              }
            }
          ));

          console.log("Students assigned to class:", assignStudentsResponse.data);
        }

        // Reset states after successful creation and assignment
        setClassName('');
        setSelectedTeacher(null);
        setSelectedStudents([]);
        setShowStudentList(false);
        setShowConfirmation(false);
        setShowSuccessMessage(true); // Show the success message
        setTimeout(() => setShowSuccessMessage(false), 3000); // Hide the message after 3 seconds
      } else {
        console.error("Error: Failed to create class.");
      }
    } catch (error) {
      console.error("Error creating class or assigning students:", error);
    }
  };

  // Handle the view change
  const handleViewChange = (view) => {
    setCurrentView(view);
    if (view === 'viewClasses') {
      fetchClasses(); // Fetch classes when "View Classes" is clicked
    }
  };

  // Handle class selection
  const handleClassSelection = (cls) => {
    setSelectedClass(cls);
    // Scroll to the class details section
    if (classDetailsRef.current) {
      classDetailsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Calculate total pages
  // const totalClassPages = Math.ceil(classes.length / itemsPerPage);
  // const totalTeacherPages = Math.ceil(teachers.length / itemsPerPage);
  // const totalStudentPages = Math.ceil(students.length / itemsPerPage);

  return (
    <div className="admin-dashboard">
      <h1 className="dashboard-title">Admin Dashboard</h1>
      
      <div className="dashboard-actions">
        <ButtonComponent onClick={() => handleViewChange('viewClasses')}>
          View All Classes
        </ButtonComponent>
        <ButtonComponent onClick={() => handleViewChange('createClass')}>
          Create Class
        </ButtonComponent>
      </div>

      {currentView === 'viewClasses' && (
        <div className="class-list-container">
          <h2>All Classes</h2>
          {classes.length > 0 ? (
            <ul>
              {classes.slice((currentClassPage - 1) * itemsPerPage, currentClassPage * itemsPerPage).map((cls) => (
                <li 
                  key={cls._id} 
                  onClick={() => handleClassSelection(cls)}
                >
                  {cls.name}
                </li>
              ))}
            </ul>
          ) : (
            <p>No classes available.</p>
          )}

          <Pagination
            totalPosts={classes.length}
            postsPerPage={itemsPerPage}
            setCurrentPage={setCurrentClassPage}
            currentPage={currentClassPage}
          />

          {selectedClass && (
            <div className="class-details" ref={classDetailsRef}>
              <h3>Class Details</h3>
              <p><strong>Name:</strong> {selectedClass.name}</p>
              <p><strong>Teacher:</strong> {selectedClass.teacher.name}</p>
              <h4>Students:</h4>
              {selectedClass.students.length > 0 ? (
                <ul>
                  {selectedClass.students.slice((currentStudentPage - 1) * itemsPerPage, currentStudentPage * itemsPerPage).map((student) => (
                    <li key={student._id}>{student.name} ({student.email})</li>
                  ))}
                </ul>
              ) : (
                <p>No students in this class.</p>
              )}

              <Pagination
                totalPosts={selectedClass.students.length}
                postsPerPage={itemsPerPage}
                setCurrentPage={setCurrentStudentPage}
                currentPage={currentStudentPage}
              />
            </div>
          )}
        </div>
      )}

      {currentView === 'createClass' && (
        <div className="create-class-container">
          <div className="input-container">
            <input 
              type="text" 
              placeholder="Enter class name" 
              value={className} 
              onChange={(e) => setClassName(e.target.value)} 
            />
            <ButtonComponent onClick={handleSelectTeacherClick}>
              Select Teacher
            </ButtonComponent>
          </div>

          {showTeacherList && (
            <div className="teacher-list">
              <h3>Select a Teacher</h3>
              <ul>
                {teachers.slice((currentTeacherPage - 1) * itemsPerPage, currentTeacherPage * itemsPerPage).map((teacher) => (
                  <li 
                    key={teacher._id} 
                    onClick={() => handleTeacherSelection(teacher)}
                  >
                    {teacher.name} ({teacher.email})
                  </li>
                ))}
              </ul>
              <Pagination
                totalPosts={teachers.length}
                postsPerPage={itemsPerPage}
                setCurrentPage={setCurrentTeacherPage}
                currentPage={currentTeacherPage}
              />
            </div>
          )}

          {showStudentList && (
            <div className="student-list">
              <h3>Select Students</h3>
              <ul>
                {students.slice((currentStudentPage - 1) * itemsPerPage, currentStudentPage * itemsPerPage).map((student) => (
                  <li 
                    key={student._id} 
                    onClick={() => handleStudentSelection(student)}
                  >
                    <input 
                      type="checkbox" 
                      checked={selectedStudents.includes(student._id)} 
                      readOnly 
                    />
                    {student.name} ({student.email})
                  </li>
                ))}
              </ul>
              <Pagination
                totalPosts={students.length}
                postsPerPage={itemsPerPage}
                setCurrentPage={setCurrentStudentPage}
                currentPage={currentStudentPage}
              />
            </div>
          )}

          <ButtonComponent onClick={handleConfirmSelection}>
            Confirm Selection
          </ButtonComponent>

          {showConfirmation && (
            <div className="confirmation-modal">
              <p>Are you sure you want to create the class with the selected teacher and students?</p>
              <ButtonComponent onClick={handleCreateClass}>
                Confirm
              </ButtonComponent>
              <ButtonComponent onClick={() => setShowConfirmation(false)}>
                Cancel
              </ButtonComponent>
            </div>
          )}

          {showSuccessMessage && (
            <div className="success-message">
              <p>Class successfully created!</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AdminDashboard;
