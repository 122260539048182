import { useEffect, useRef } from "react";
import "./TableComponent.styles.scss";
import { useState } from "react";
import { connect } from "react-redux";
import { setResultData } from "./../../../redux/textractdata/textractdata.action";

const mapDispatchToProps = (dispatch) => ({
  setResultData: (data) => dispatch(setResultData(data)),
});

// Global values and functions
const inputValueChange = (e, data, setData, setTruthie, truthie) => {
  const [rowIndex, colKey] = e.target.name.split(":");
  const inputValue = e.target.value;

  // Clone the data to avoid mutating the original object
  const updatedData = { ...data };

  // Determine the table index and adjust the logic accordingly
  const tableType = e.target.closest('table').dataset.tableType;

  if (tableType === 'insulation') {
    // Handle Insulation table structure
    if (updatedData.receivedData[0] && updatedData.receivedData[0][0]) {
      const tableData = updatedData.receivedData[0][0];
      
      if (tableData[rowIndex]) {
        tableData[rowIndex][colKey] = inputValue; // Update the cell value
      } else {
        console.error(`Row ${rowIndex} does not exist in the Insulation table.`);
      }
    } else {
      console.error("Data structure is incorrect or missing for Insulation.");
    }
  } else {
    // Handle Chromatography table structure
    if (updatedData.receivedData[0]) {
      const tableData = updatedData.receivedData[0];
      
      if (tableData[rowIndex]) {
        tableData[rowIndex][colKey] = inputValue; // Update the cell value
      } else {
        console.error(`Row ${rowIndex} does not exist in the Chromatography table.`);
      }
    } else {
      console.error("Data structure is incorrect or missing for Chromatography.");
    }
  }

  // Update the state with the new data
  setData(updatedData);
  setTruthie(!truthie); // Toggle truthie to trigger a re-render
};





const scroll = (myRef) => {
  if (myRef.current) {
    setTimeout(() => {
      myRef.current.scrollIntoView({ behavior: "smooth" });
    }, 1000);
  }
};

const Chromatography = ({ data, setResultData, result }) => {
  // Defining react-Hooks
  const [truthie, setTruthie] = useState(false);
  const [localData, setLocalData] = useState(data); // Local state to manage data
  const myRef = useRef();

  useEffect(() => {
    setResultData(localData);
    scroll(myRef);
  }, [truthie, localData, setResultData]);

  // Function to add empty columns if less than 4
  const normalizeColumns = (row) => {
    const entries = Object.entries(row);
    const normalizedEntries = entries.length < 4 
      ? [...entries, ['', '']] // Add empty columns
      : entries.slice(0, 4); // Keep only the first 2 columns

    return normalizedEntries;
  };


  return (
    <>
      <table ref={myRef}>
        <thead>
          <tr>
            <th>Food dye Colour</th>
            <th>Distance travelled by food dye (cm)</th>
            <th>Distance travelled by solvent (cm)</th>
            <th>Rf value</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(localData.receivedData[0])
            .slice(1) // Skip the first entry
            .map(([key, row], i) => (
              <tr key={i}>
                {normalizeColumns(row).map(([colKey, value]) => (
                  <td key={colKey}>
                    <input
                      onChange={(e) => {
                        inputValueChange(e, localData, setLocalData, setTruthie, truthie);
                      }}
                      type="text"
                      name={`${key}:${colKey}`}
                      value={value || ''} // Ensure it doesn't break on undefined values
                    />
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
};

/////
/////
/////
//Photosynthesis Table Component

const Photosynthesis = ({ data, setResultData, result }) => {
  // Defining react-Hooks
  const [truthie, setTruthie] = useState(false);
  const [localData, setLocalData] = useState(data); // Local state to manage data
  const myRef = useRef();

  useEffect(() => {
    setResultData(localData);
    scroll(myRef);
  }, [truthie, localData, setResultData]);

  // Function to add empty columns if less than 2
  const normalizeColumns = (row) => {
    const entries = Object.entries(row);
    const normalizedEntries = entries.length < 5 
      ? [...entries, ['', '']] // Add empty columns
      : entries.slice(0, 5); // Keep only the first 2 columns

    return normalizedEntries;
  };

  /////
  /////
  return (
    <>
      <table ref={myRef}>
        <thead>
          <tr>
            <th>Distance from plant (cm)</th>
            <th>Number of bubbles (1st Attempt)</th>
            <th>Number of bubbles (2nd Attempt)</th>
            <th>Number of bubbles (3rd Attempt)</th>
            <th>Average number of bubbles</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(localData.receivedData[0])
            .slice(1) // Skip the first entry
            .map(([key, row], i) => (
              <tr key={i}>
                {normalizeColumns(row).map(([colKey, value]) => (
                  <td key={colKey}>
                    <input
                      onChange={(e) => {
                        inputValueChange(e, localData, setLocalData, setTruthie, truthie);
                      }}
                      type="text"
                      name={`${key}:${colKey}`}
                      value={value || ''} // Ensure it doesn't break on undefined values
                    />
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
      {/* ///// ///// */}
    </>
  );
};

/////
/////
/////
const Absorptionandradiation = ({ data, setResultData, result }) => {
  const [truthie, setTruthie] = useState(false);
  const [localData, setLocalData] = useState(data); // Local state to manage data
  const myRef = useRef();

  useEffect(() => {
    setResultData(localData);
    scroll(myRef);
  }, [truthie, localData, setResultData]);

  // Function to add empty columns if less than 2
  const normalizeColumns = (row) => {
    const entries = Object.entries(row);
    const normalizedEntries = entries.length < 2 
      ? [...entries, ['', '']] // Add empty columns
      : entries.slice(0, 2); // Keep only the first 2 columns

    return normalizedEntries;
  };

  return (
    <>
      <table ref={myRef}>
        <thead>
          <tr>
            <th>Distance from heat source (cm)</th>
            <th>Temperature (°C)</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(localData.receivedData[0])
            .slice(1) // Skip the first entry
            .map(([key, row], i) => (
              <tr key={i}>
                {normalizeColumns(row).map(([colKey, value]) => (
                  <td key={colKey}>
                    <input
                      onChange={(e) => {
                        inputValueChange(e, localData, setLocalData, setTruthie, truthie);
                      }}
                      type="text"
                      name={`${key}:${colKey}`}
                      value={value || ''} // Ensure it doesn't break on undefined values
                    />
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
      {/* ///// ///// */}
    </>
  );
};

/////
/////
/////
const Neutralisation = ({ data, setResultData, result }) => {
  const [truthie, setTruthie] = useState(false);
  const [localData, setLocalData] = useState(data); // Local state to manage data
  const myRef = useRef();

  useEffect(() => {
    setResultData(localData);
    scroll(myRef);
  }, [truthie, localData, setResultData]);

  // Function to add empty columns if less than 4
  const normalizeColumns = (row) => {
    const entries = Object.entries(row);
    const normalizedEntries = entries.length < 4 
      ? [...entries, ['', '']] // Add empty columns
      : entries.slice(0, 4); // Keep only the first 4 columns

    return normalizedEntries;
  };

  return (
    <>
      <table ref={myRef}>
        <thead>
          <tr>
            <th>Number of drops of acid</th>
            <th>Number of drops of alkali</th>
            <th>What colour did the litmus paper turn?</th>
            <th>What pH is it?</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(localData.receivedData[0])
            .slice(1) // Skip the first entry
            .map(([key, row], i) => (
              <tr key={i}>
                {normalizeColumns(row).map(([colKey, value]) => (
                  <td key={colKey}>
                    <input
                      onChange={(e) => {
                        inputValueChange(e, localData, setLocalData, setTruthie, truthie);
                      }}
                      type="text"
                      name={`${key}:${colKey}`}
                      value={value || ''} // Ensure it doesn't break on undefined values
                    />
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
      {/* ///// ///// */}
    </>
  );
};
/////
/////
/////
const Springextension = ({ data, setResultData, result }) => {
  const [truthie, setTruthie] = useState(false);
  const [localData, setLocalData] = useState(data); // Local state to manage data
  const myRef = useRef();

  useEffect(() => {
    setResultData(localData);
    scroll(myRef);
  }, [truthie, localData, setResultData]);

  // Function to add empty columns if less than 4
  const normalizeColumns = (row) => {
    const entries = Object.entries(row);
    const normalizedEntries = entries.length < 4 
      ? [...entries, ['', '']] // Add empty columns
      : entries.slice(0, 4); // Keep only the first 4 columns

    return normalizedEntries;
  };

  return (
    <>
      <table ref={myRef}>
        <thead>
          <tr>
            <th>Load (g)</th>
            <th>Old length (cm)</th>
            <th>New length (cm)</th>
            <th>Extension (cm)</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(localData.receivedData[0])
            .slice(1) // Skip the first entry
            .map(([key, row], i) => (
              <tr key={i}>
                {normalizeColumns(row).map(([colKey, value]) => (
                  <td key={colKey}>
                    <input
                      onChange={(e) => {
                        inputValueChange(e, localData, setLocalData, setTruthie, truthie);
                      }}
                      type="text"
                      name={`${key}:${colKey}`}
                      value={value || ''} // Ensure it doesn't break on undefined values
                    />
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
      {/* ///// ///// */}
    </>
  );
};

/////
/////
/////
const Fieldinvestigation = ({ data, setResultData, result }) => {

  const [truthie, setTruthie] = useState(false);
  const [localData, setLocalData] = useState(data); // Local state to manage data
  const myRef = useRef();

  useEffect(() => {
    setResultData(localData);
    scroll(myRef);
  }, [truthie, localData, setResultData]);

  // Function to add empty columns if less than 5
  const normalizeColumns = (row) => {
    const entries = Object.entries(row);
    const normalizedEntries = entries.length < 5 
      ? [...entries, ['', '']] // Add empty columns
      : entries.slice(0, 5); // Keep only the first 5 columns

    return normalizedEntries;
  };

  return (
    <>
      <table ref={myRef}>
        <thead>
          <tr>
            <th>Species</th>
            <th>Number of squares this species is in</th>
            <th>Max number of squares</th>
            <th>Fraction of squares he species was in</th>
            <th>Population coverage in area (%)</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(localData.receivedData[0])
            .slice(1) // Skip the first entry
            .map(([key, row], i) => (
              <tr key={i}>
                {normalizeColumns(row).map(([colKey, value]) => (
                  <td key={colKey}>
                    <input
                      onChange={(e) => {
                        inputValueChange(e, localData, setLocalData, setTruthie, truthie);
                      }}
                      type="text"
                      name={`${key}:${colKey}`}
                      value={value || ''} // Ensure it doesn't break on undefined values
                    />
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
      {/* ///// ///// */}
    </>
  );
};

const Osmosis = ({ data, setResultData, result }) => {
  const [truthie, setTruthie] = useState(false);
  const [localData, setLocalData] = useState(data); // Local state to manage data
  const myRef = useRef();

  useEffect(() => {
    setResultData(localData);
    scroll(myRef);
  }, [truthie, localData, setResultData]);

  // Function to add empty columns if less than 3
  const normalizeColumns = (row) => {
    const entries = Object.entries(row);
    const normalizedEntries = entries.length < 3 
      ? [...entries, ['', '']] // Add empty columns
      : entries.slice(0, 3); // Keep only the first 3 columns

    return normalizedEntries;
  };

  return (
    <>
      <table ref={myRef}>
        <thead>
          <tr>
            <th>Original Length (cm)</th>
            <th>Final length (cm)</th>
            <th>What was the increase in size? (cm)</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(localData.receivedData[0])
            .slice(1) // Skip the first entry
            .map(([key, row], i) => (
              <tr key={i}>
                {normalizeColumns(row).map(([colKey, value]) => (
                  <td key={colKey}>
                    <input
                      onChange={(e) => {
                        inputValueChange(e, localData, setLocalData, setTruthie, truthie);
                      }}
                      type="text"
                      name={`${key}:${colKey}`}
                      value={value || ''} // Ensure it doesn't break on undefined values
                    />
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
      {/* ///// ///// */}
    </>
  );
};

const Waterseparation = ({ data, setResultData, result }) => {

  const [truthie, setTruthie] = useState(false);
  const [localData, setLocalData] = useState(data); // Local state to manage data
  const myRef = useRef();

  useEffect(() => {
    setResultData(localData);
    scroll(myRef);
  }, [truthie, localData, setResultData]);

  // Function to add empty columns if less than 2
  const normalizeColumns = (row) => {
    const entries = Object.entries(row);
    const normalizedEntries = entries.length < 4 
      ? [...entries, ['', '']] // Add empty columns
      : entries.slice(0, 4); // Keep only the first 2 columns

    return normalizedEntries;
  };

  return (
    <>
      <table ref={myRef}>
        <thead>
          <tr>
            <th>Name of liquid evaporated</th>
            <th>What salt or precipitate was left</th>
            <th>How much salt or precipitate was left?</th>
            <th>How long did it take to evaporate?</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(localData.receivedData[0])
            .slice(1) // Skip the first entry
            .map(([key, row], i) => (
              <tr key={i}>
                {normalizeColumns(row).map(([colKey, value]) => (
                  <td key={colKey}>
                    <input
                      onChange={(e) => {
                        inputValueChange(e, localData, setLocalData, setTruthie, truthie);
                      }}
                      type="text"
                      name={`${key}:${colKey}`}
                      value={value || ''} // Ensure it doesn't break on undefined values
                    />
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
      {/* ///// ///// */}
    </>
  );
};

const Insulation = ({ data, setResultData, result }) => {
  const [truthie, setTruthie] = useState(false);
  const [localData, setLocalData] = useState(data); // Local state to manage data
  const myRef = useRef();

  // console.log(localData);

  useEffect(() => {
    setResultData(localData);
    scroll(myRef);
  }, [truthie, localData, setResultData]);

  // Function to add empty columns if less than 2
  const normalizeColumns = (row) => {
    const entries = Object.entries(row);
    const normalizedEntries = entries.length < 2 
      ? [...entries, ['', '']] // Add empty columns
      : entries.slice(0, 2); // Keep only the first 2 columns

    return normalizedEntries;
  };

  return (
    <>
    <table ref={myRef} data-table-type="insulation">
        <thead>
          <tr>
            <th>Time (Minutes)</th>
            <th>Temperature (°C)</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(localData.receivedData[0][0])
            .slice(1) // Skip the first entry
            .map(([key, row], i) => (
              <tr key={i}>
                {normalizeColumns(row).map(([colKey, value]) => (
                  <td key={colKey}>
                    <input
                      onChange={(e) => {
                        inputValueChange(e, localData, setLocalData, setTruthie, truthie);
                      }}
                      type="text"
                      name={`${key}:${colKey}`}
                      value={value || ''} // Ensure it doesn't break on undefined values
                    />
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
};



const exportModule = {
  Chromatography: connect(null, mapDispatchToProps)(Chromatography),
  Photosynthesis: connect(null, mapDispatchToProps)(Photosynthesis),
  Absorptionandradiation: connect(null, mapDispatchToProps)(Absorptionandradiation),
  Neutralisation: connect(null, mapDispatchToProps)(Neutralisation),
  Springextension: connect(null, mapDispatchToProps)(Springextension),
  Fieldinvestigation: connect(null, mapDispatchToProps)(Fieldinvestigation),
  Osmosis: connect(null, mapDispatchToProps)(Osmosis),
  Waterseparation: connect(null, mapDispatchToProps)(Waterseparation),
  Insulation: connect(null, mapDispatchToProps)(Insulation),
};

export default exportModule;