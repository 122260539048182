import axios from 'axios';

const refreshToken = async () => {
  const getToken = () => localStorage.getItem('token');
  const getRefreshToken = () => localStorage.getItem('refreshToken');

  try {
    const response = await axios.post(
      "https://backend-master-v8ob.onrender.com/api/v1/stem@home/auth/refresh-token",
      { refreshToken: getRefreshToken() },
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${getToken()}`
        }
      }
    );
    localStorage.setItem('token', response.data.token);
    return response.data.token;
  } catch (error) {
    console.error("Error refreshing token:", error);
    // Handle token refresh failure (e.g., redirect to login page)
    window.location.href = "/login";
    return null;
  }
};

export default refreshToken;
