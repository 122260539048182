import React, { useEffect, useState } from 'react';
import { Navbar, Container, Nav } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import './Header.css';

const Header = () => {
  const token = localStorage.getItem("token");
  const [userName, setUserName] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      const storedUserName = localStorage.getItem("userName");
      const storedIsAdmin = localStorage.getItem("isAdmin") === 'true';
      setUserName(storedUserName);
      setIsAdmin(storedIsAdmin);
    }
  }, [token]);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userName");
    localStorage.removeItem("isAdmin");
    navigate("/login");
  };

  const handleDashboardClick = () => {
    if (isAdmin) {
      navigate("/admin-dashboard");
    } else {
      navigate("/dashboard");
    }
  };

  const handleBrandClick = () => {
    if (isAdmin) {
      navigate("/admin-dashboard");
    } else {
      navigate("/dashboard");
    }
  };

  return (
    <>
      <Navbar className={token ? "navbar-logged-in" : "navbar-not-logged-in"} fixed="top" variant="dark" expand="lg">
        <Container>
          <Navbar.Brand onClick={handleBrandClick} style={{ cursor: 'pointer' }}>
            <img
              alt=""
              src={process.env.PUBLIC_URL + "/logo1.svg"}
              width="70"
              height="70"
              className="d-inline-block align-top"
              style={{ marginRight: '10px' }}
            />
            {token ? `${userName}` : "Not Logged In"}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-auto">
              {token ? (
                <>
                  <Nav.Link className='nav-link' onClick={handleDashboardClick}>
                    Dashboard
                  </Nav.Link>
                  <Nav.Link className="nav-link logout-button" onClick={handleLogout}>
                    Log out
                  </Nav.Link>
                </>
              ) : (
                <>
                  <Nav.Link as={Link} to="/login" className='nav-link'>
                    Login
                  </Nav.Link>
                  <Nav.Link as={Link} to="/register" className='nav-link'>
                    Sign Up
                  </Nav.Link>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className="main-content">
        {/* Your main content goes here */}
      </div>
    </>
  );
};

export default Header;