import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import ButtonComponent from '../../components/ui/buttonComponent/button.component';
import './StartSubmission.css'; // Import the CSS file for styling

const StartSubmission = () => {
  const navigate = useNavigate();
  const [firstOption, setFirstOption] = useState('');
  const [secondOption, setSecondOption] = useState('');

  useEffect(() => {
    const role = localStorage.getItem('role');
    if (role === 'teacher') {
      navigate('/dashboard');
      return;
    }

    const navbarHeight = document.querySelector('.navbar').offsetHeight;
    const extraGap = 20; // Small gap in pixels
    document.querySelector('.center-form').style.paddingTop = `${navbarHeight + extraGap}px`;
  }, [navigate]);

  const handleFirstOptionChange = (event) => {
    setFirstOption(event.target.value);
    setSecondOption(''); // Reset second option when first option changes
  };

  const handleSecondOptionChange = (event) => {
    setSecondOption(event.target.value);
  };

  const handleNext = () => {
    // Check if both options are selected before navigating
    if (firstOption && secondOption) {
      navigate('/takeImage', { 
        state: { 
          topic: firstOption, 
          practical: secondOption, 
          fromStartSubmission: true,
          subject: firstOption // Add this line to pass the subject
        } 
      });
    } else {
      alert("Please select both options before proceeding."); // Alert user to select options
    }
  };

  const getDropdownStyle = () => {
    switch (firstOption) {
      case 'Biology':
        return { backgroundColor: 'lightgreen' };
      case 'Chemistry':
        return { backgroundColor: 'lightblue' };
      case 'Physics':
        return { backgroundColor: 'violet' }; 
      default:
        return {};
    }
  };

  return (
    <div className="center-form">
      <h1>Start Submission</h1>
      <Form.Group controlId="formFirstOption">
        <Form.Label>First Option</Form.Label>
        <Form.Control 
          as="select" 
          value={firstOption} 
          onChange={handleFirstOptionChange}
          style={getDropdownStyle()} // Apply dynamic style to first dropdown
        >
          <option value="">Select an option</option>
          <option value="Biology">Biology</option>
          <option value="Chemistry">Chemistry</option>
          <option value="Physics">Physics</option>
        </Form.Control>
      </Form.Group>
      {firstOption && (
        <Form.Group controlId="formSecondOption">
          <Form.Label>Second Option</Form.Label>
          <Form.Control 
            as="select" 
            value={secondOption} 
            onChange={handleSecondOptionChange}
            style={getDropdownStyle()} // Apply dynamic style to second dropdown
          >
            <option value="">Select a practical</option>
            {firstOption === 'Biology' && (
              <>
                <option value="Photosynthesis">Photosynthesis</option>
                <option value="Field Investigation">Field Investigation</option>
                <option value="Osmosis">Osmosis</option>
              </>
            )}
            {firstOption === 'Chemistry' && (
              <>
                <option value="Neutralisation">Neutralisation</option>
                <option value="Water Separation">Water Separation</option>
                <option value="Chromatography">Chromatography</option>
              </>
            )}
            {firstOption === 'Physics' && (
              <>
                <option value="Spring Extension">Spring Extension</option>
                <option value="Absorption Radiation">Absorption Radiation</option>
                <option value="Insulation">Insulation</option>
              </>
            )}
          </Form.Control>
        </Form.Group>
      )}
      <ButtonComponent onClick={handleNext}>Submit</ButtonComponent>
    </div>
  );
};

export default StartSubmission;