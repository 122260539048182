import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
const RenderRoute = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });


  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    // Event listener to update dimensions on resize
    window.addEventListener("resize", handleResize);
    console.log(windowSize.width);
    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowSize.width]); // Empty dependency array means this effect will only run once after the initial render
  console.log("WINDOW RESIZE HERE");
  return (
    <>
      {windowSize.width <= 1028 ? (
        <Outlet />
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100vh",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h2>
            PLEASE NOTE, THIS APP DOES NOT CURRENTLY SUPPORT THIS DEVICE WIDTH,
            PLEASE USE YOUR MOBILE DEVICE
          </h2>
        </div>
      )}
    </>
  );
};

export default RenderRoute;
