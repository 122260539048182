import React, { useEffect, useState } from "react";
import axios from "axios";
import Pagination from "../../components/ui/Pagination/pagination.component";
import refreshToken from "../../utils/apiUtils";
import "./submissions.styles.scss";

const Submissions = () => {
  const [results, setResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(5);
  const [selectedSubmission, setSelectedSubmission] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState("");
  const [error, setError] = useState(null);

  const getToken = () => localStorage.getItem('token');

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://backend-master-v8ob.onrender.com/api/v1/stem@home/student/results",
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${getToken()}`
          }
        }
      );
      setResults(response.data.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Token is expired, try to refresh
        const newToken = await refreshToken();
        if (newToken) {
          // Retry the request with the new token
          const retryResponse = await axios.get(
            "https://backend-master-v8ob.onrender.com/api/v1/stem@home/student/results",
            {
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${newToken}`
              }
            }
          );
          setResults(retryResponse.data.data);
        }
      } else {
        setError("Error fetching data");
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmissionClick = async (submissionId) => {
    try {
      const response = await axios.get(
        `https://backend-master-v8ob.onrender.com/api/v1/stem@home/student/results/${submissionId}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${getToken()}`
          }
        }
      );
      setSelectedSubmission(response.data.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newToken = await refreshToken();
        if (newToken) {
          const retryResponse = await axios.get(
            `https://backend-master-v8ob.onrender.com/api/v1/stem@home/student/results/${submissionId}`,
            {
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${newToken}`
              }
            }
          );
          setSelectedSubmission(retryResponse.data.data);
        }
      } else {
        setError("Error fetching submission details");
      }
    }
  };

  const handleImageClick = (url) => {
    setSelectedImageUrl(url);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImageUrl("");
  };

  const getButtonColor = (subject) => {
    if (!subject) return '';
    switch (subject.toLowerCase()) {
      case 'biology':
        return 'green-button';
      case 'chemistry':
        return 'blue-button';
      case 'physics':
        return 'pink-button';
      default:
        return '';
    }
  };

  const getBackgroundColor = (practical) => {
    if (!practical) return '';

    switch (practical.toLowerCase()) {
      case 'photosynthesis':
      case 'field investigation':
      case 'osmosis':
        return 'green-background';
      case 'chromatography':
      case 'water separation':
      case 'neutralisation':
        return 'blue-background';
      case 'spring extension':
      case 'absorption radiation':
      case 'insulation':
        return 'pink-background white-text';
      default:
        return '';
    }
  };

  return (
    <div className="submissions-container">
      <h1 className="submissions-title">Submissions</h1>
      <div className="submissions-list">
        {results.length > 0 ? (
          <ul>
            {results.slice((currentPage - 1) * postsPerPage, currentPage * postsPerPage).map((result, index) => (
              <li
                key={index}
                onClick={() => handleSubmissionClick(result._id)}
                className={`submission-button ${getButtonColor(result.practical)}`}
              >
                {result.submission_date} - {result.practical}
              </li>
            ))}
          </ul>
        ) : (
          <p>No submissions found.</p>
        )}
      </div>
      {selectedSubmission && (
        <div
          className={`submission-details ${getBackgroundColor(selectedSubmission.practical)}`}
        >
          <h2>Submission Details</h2>
          <p><strong>Subject: </strong> {
            (() => {
              switch (selectedSubmission.practical.toLowerCase()) {
                case 'photosynthesis':
                case 'field investigation':
                case 'osmosis':
                  return 'Biology';
                case 'chromatography':
                case 'water separation':
                case 'neutralisation':
                  return 'Chemistry';
                case 'spring extension':
                case 'absorption radiation':
                case 'insulation':
                  return 'Physics';
                default:
                  return 'Unknown';
              }
            })()
          }</p>
          <p><strong>Practical: </strong> {selectedSubmission.practical}</p>
          <p><strong>Images: </strong></p>
          {selectedSubmission.imageUrls && selectedSubmission.imageUrls.length > 0 ? (
            <div className="image-gallery">
              {selectedSubmission.imageUrls.map((url, index) => (
                <img
                  key={index}
                  src={url}
                  alt={`Submission image ${index + 1}`}
                  className="submission-image"
                  onClick={() => handleImageClick(url)}
                />
              ))}
            </div>
          ) : (
            <p>No images available</p>
          )}
        </div>
      )}
      <Pagination
        totalPosts={results.length}
        postsPerPage={postsPerPage}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />
      {isModalOpen && (
        <div className="submissions-modal">
          <div className="submissions-modal-content">
            <img src={selectedImageUrl} alt="Selected" className="submissions-modal-image" />
            <button className="submissions-close-button" onClick={closeModal}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Submissions;
